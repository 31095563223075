import IconButton from "@mui/material/IconButton";
import CloseIcon from "@icons/Close";
import { Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@ds/components/Button";
import Text from "@ds/components/Text";
import Dialog from "@mui/material/Dialog";
import { RootState } from "@redux/reducers";
import { useSelector } from "react-redux";
import { setCloseVerifyDialog, setClearVerifyDialog, setClearAddTicketData } from "@redux/actions";
import { useDispatch } from "react-redux";
import useResponsive from "@helpers/useResponsive";
import Image from "next/image";
import color from "@definitions/mui/color";
import IconCallCenter from "@icons/IconCallCenter";
import IconCopyCallCenter from "@icons/IconCopyCallCenter";
import IconSend from "@icons/IconSend";
import IconEmail from "@icons/IconEmail";
import { setTdsTicketId } from "@redux/actions";
import {
  DialogCloseButton,
  DialogBody,
  DialogBodyMobile,
  DialogBoxTitle,
  DialogTitle,
  DialogBoxPIC,
  DialogBoxPICMobile,
  DialogBoxPICInfo,
  DialogNumberPIC,
  DialogNumberPICInfo,
  DialogBoxIdentityPIC,
  DialogBoxIdentityPICInfo,
  CustomDivider,
  DialogMain,
  DialogMainMobile,
  DialogRowGeneral,
  BoxList,
  DotBullets,
} from "./styles";
import { useState } from "react";

type TWarningActionButton = {
  label: string;
  variant: "primary" | "secondary" | "text";
  loading?: boolean;
  onClick?: () => void;
  type?: string;
};

const VerificationDialog: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const smClient = useResponsive("sm");
  const { verifyDialog } = useSelector((state: RootState) => ({
    verifyDialog: state.rootReducer.verifyDialog,
  }));
  const {
    open,
    title,
    itemList,
    informationList,
    hasApplyButton,
    applyButton,
    image,
    messages,
    description,
    listItem,
    dataSegmentContact,
  } = verifyDialog;

  const _handleOnClose = () => {
    dispatch(setClearAddTicketData());
    dispatch(setTdsTicketId(""));
    dispatch(setClearVerifyDialog());
    dispatch(setCloseVerifyDialog());
  };

  const handleCopyCallCenter = (item: string) => {
    navigator.clipboard.writeText(`${item}`);
  };

  const handleOpenEmailApp = (item: string) => {
    window.location.href = `mailto:${item}`;
  };

  const handleClick = async ({onClick, type=''}: any) => {
    if(type === 'submit'){
      setIsLoading(true);
      await onClick();
      setIsLoading(false);
    }else{
      await onClick();
    }
  };

  const _renderImage = () => {
    return (
      <Box mb={2} textAlign="center">
        <Image
          src={image ? image : ""}
          alt="Data Tidak di Temukan"
          height={220}
          width={264}
          blurDataURL={image ? image : ""}
          placeholder="blur"
        />
      </Box>
    );
  };

  const _renderActionButton = () => {
    return (
      <>
        {applyButton?.map(
          ({ label, variant, loading, type, onClick, ...rest }:
             Partial<TWarningActionButton>, key: number) => {
            return (
              <Box mb={2} key={key}>
                <Button loading={loading || (type === 'submit' && isLoading)} onClick={()=> handleClick({onClick, type})} size="large" width="100%" variant={variant} {...rest}>
                  {label}
                </Button>
              </Box>
            );
          },
        )}
      </>
    );
  };

  const _renderMessages = () => {
    return (
      <div>
        {messages?.map((message: string[], key: number) => {
          const child = message[0];
          const colorMUI = message[1] === "light" ? color.general.mid : color.general.main;
          const fontWeight = message[2] || "500";
          const isTitle = message[3] === "title";

          return (
            <Box key={key} mb={3}>
              <Text variant={isTitle ? "h2" : "body1"}>
                <Box
                  sx={{ color: colorMUI }}
                  fontSize={isTitle ? "24px" : "16px"}
                  fontWeight={fontWeight}
                  textAlign="center"
                >
                  {child}
                </Box>
              </Text>
            </Box>
          );
        })}
      </div>
    );
  };

  const renderBottomMobileVerifyDialog = () => {
    return (
      <Dialog open={true} scroll="body">
        <Box css={DialogMainMobile}>
          <Box css={DialogBodyMobile}>
            <Box css={DialogBoxTitle}>
              <IconButton css={DialogCloseButton} onClick={_handleOnClose} size="large">
                <CloseIcon style={{ height: 16, width: 16 }} />
              </IconButton>
            </Box>
            <Box css={DialogBoxTitle}>
              <Text variant="h2" css={DialogTitle}>
                {title}
              </Text>
            </Box>

            {description && (
              <Box css={DialogBoxTitle} sx={{ textAlign: "center" }}>
                <Text variant="body1" weight="400" color="inactive-secondary">
                  {description}
                </Text>
              </Box>
            )}

            {listItem && listItem.length !== 0 && (
              <ul style={{ listStyle: "none", padding: "10px 14px" }}>
                {listItem.map((dataStep: any) => {
                  return (
                    <li css={BoxList}>
                      <div css={DotBullets}></div>
                      <Text variant="body1" weight="400" color="inactive-secondary">
                        {dataStep.item}
                      </Text>
                    </li>
                  );
                })}
              </ul>
            )}

            {dataSegmentContact && dataSegmentContact.length !== 0 && (
              <Box>
                {dataSegmentContact.map((item: { name: string; value: string }, index: number) => {
                  return (
                    <Box css={DialogBoxPICMobile}>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box css={DialogNumberPIC}>
                          {index === 0 && <IconCallCenter />}
                          {index === 1 && <IconEmail />}
                        </Box>
                        <Box css={DialogBoxIdentityPIC}>
                          <Text variant="caption1" color="general-main" weight="400">{item.name}</Text>
                          <Text variant="body2" weight="700">
                            {item.value}
                          </Text>
                        </Box>
                      </Box>
                      <Box>
                        {index === 0 && (
                          <IconCopyCallCenter
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleCopyCallCenter(item.value)}
                          />
                        )}
                        {index === 1 && (
                          <IconSend
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleOpenEmailApp(item.value)}
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}

            {image && <Box>{_renderImage()}</Box>}

            {messages.length !== 0 && <Box>{_renderMessages()}</Box>}

            <Box>
              {itemList?.length !== 0 &&
                itemList.map((item: { itemLabel: string; itemValue: string }) => {
                  return (
                    <Grid container sx={{ marginBottom: "5px" }}>
                      <Grid item md={6} xs={6}>
                        <Text variant="body2">{item.itemLabel}</Text>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Text variant="body2">:</Text>
                          <Text variant="body2" sx={{ marginLeft: "2px" }}>
                            {item.itemValue}
                          </Text>
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
            </Box>

            {informationList?.map(
              (
                info: {
                  infoTitle: string;
                  infoList: {
                    infoLabel: string;
                    infoValue: string;
                  }[];
                },
                index: number,
              ) => {
                return (
                  <div>
                    <Box mt={2}>
                      <Text variant="body2" color="inactive-secondary">
                        {info.infoTitle}
                      </Text>
                      {info?.infoList?.map(
                        (
                          item: {
                            infoLabel: string;
                            infoValue: string;
                          },
                          index: number,
                        ) => {
                          return (
                            <Box css={DialogBoxPICInfo} key={`item-data-${index}`}>
                              <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Grid container>
                                  <Grid item xs={1}>
                                    <Box css={DialogNumberPICInfo}>{index + 1}</Box>
                                  </Grid>
                                  <Grid item xs={11}>
                                    <Box css={DialogBoxIdentityPICInfo}>
                                      <Text variant="body2">{item.infoLabel}</Text>
                                      <Text variant="body2" color="inactive-secondary">
                                        {item.infoValue}
                                      </Text>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          );
                        },
                      )}
                    </Box>
                    {index !== informationList?.length - 1 && (
                      <Divider orientation="horizontal" css={CustomDivider} />
                    )}
                  </div>
                );
              },
            )}

            <Box sx={{ marginTop: "30px" }}>{hasApplyButton && _renderActionButton()}</Box>
          </Box>
        </Box>
      </Dialog>
    );
  };

  const renderDesktopVerifyDialog = () => {
    return (
      <Dialog
        open={true}
        css={DialogMain}
        fullWidth
        maxWidth={"xs"}
        scroll="body"
        sx={{ marginTop: "50px" }}
      >
        <Box css={DialogBody}>
          <Box css={DialogBoxTitle}>
            <IconButton css={DialogCloseButton} onClick={_handleOnClose} size="large">
              <CloseIcon style={{ height: 16, width: 16 }} />
            </IconButton>
          </Box>
          <Box css={DialogBoxTitle}>
            <Text variant="h2" css={DialogTitle}>
              {title}
            </Text>
          </Box>

          <Box>
            {itemList?.length !== 0 &&
              itemList.map((item: { itemLabel: string; itemValue: string }) => {
                return (
                  <Grid container sx={{ marginBottom: "5px" }}>
                    <Grid item md={6}>
                      <Text variant="body2">{item.itemLabel}</Text>
                    </Grid>
                    <Grid item md={6}>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Text variant="body2">:</Text>
                        <Text variant="body2" sx={{ marginLeft: "2px" }}>
                          {item.itemValue}
                        </Text>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
          </Box>

          {informationList?.map(
            (
              info: {
                infoTitle: string;
                infoList: {
                  infoLabel: string;
                  infoValue: string;
                }[];
              },
              index: number,
            ) => {
              return (
                <div>
                  <Box>
                    <Text variant="body2" color="inactive-secondary">
                      {info.infoTitle}
                    </Text>
                    {info?.infoList?.map(
                      (
                        item: {
                          infoLabel: string;
                          infoValue: string;
                        },
                        index: number,
                      ) => {
                        return (
                          <Box css={DialogBoxPIC} key={`item-data-${index}`}>
                            <Grid container css={DialogRowGeneral}>
                              <Grid item xs={1}>
                                <Box css={DialogNumberPIC}>{index + 1}</Box>
                              </Grid>
                              <Grid item xs={11}>
                                <Box css={DialogBoxIdentityPIC}>
                                  <Text variant="body2">{item.infoLabel}</Text>
                                  <Text variant="body2" color="inactive-secondary">
                                    {item.infoValue}
                                  </Text>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      },
                    )}
                  </Box>
                  {index !== informationList?.length - 1 && (
                    <Divider orientation="horizontal" css={CustomDivider} />
                  )}
                </div>
              );
            },
          )}

          <Box sx={{ marginTop: "30px" }}>{hasApplyButton && _renderActionButton()}</Box>
        </Box>
      </Dialog>
    );
  };

  return (
    open && <Box>{smClient ? renderBottomMobileVerifyDialog() : renderDesktopVerifyDialog()}</Box>
  );
};

export default VerificationDialog;
