import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

type sizeProps = "xs" | "sm" | "md" | "lg" | "xl";

const useResponsive = (size: sizeProps, noSsr = false) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(size), { noSsr });
};

export default useResponsive;
