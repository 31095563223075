/* eslint-disable no-unused-vars */
import React from "react";
import styled from "@emotion/styled";
import { Button as MuiButton, ButtonProps } from "@mui/material";
import custom, { fontFamSecondary } from "@definitions/mui/custom";
import color from "@definitions/mui/color";
import AnimateLoader from "@ds/components/AnimateLoader";
import { useTranslation } from "next-i18next";

interface IButtonBaseRoot extends Omit<ButtonProps, "color" | "size" | "startIcon" | "variant"> {
  disabled?: boolean;
  loading?: boolean;
  onClick?: (param?: any) => void;
  size?: "small" | "medium" | "large";
  startIcon?: React.ReactNode;
  type?: "button" | "reset" | "submit";
  variant?: "primary" | "secondary" | "text" | "outlined";
  width?: number | string | "auto";
}

const ButtonRoot = styled(MuiButton)(({ disabled, loading, size, variant, width }: any) => ({
  fontFamily: fontFamSecondary,
  ...(width && {
    width: width,
  }),
  ...(variant && {
    ...custom.button[variant],
    borderRadius: "24px",
    fontSize: 16,
    minWidth: "64px",
    padding: `8px 36px`,
  }),
  ...(size && {
    ...custom.typography["button-" + size],
  }),
  ...(disabled && {
    backgroundColor: color.inactive.primary,
    border: `1px solid ${color.inactive.primary}`,
    boxShadow: "none",
    color: color.inactive.secondary + "!important",
  }),
  ...(disabled || loading && {
    backgroundColor: color.inactive.primary,
    border: `1px solid ${color.inactive.primary}`,
    boxShadow: "none",
    color: color.inactive.secondary + "!important",
  }),
}));

const Button = React.forwardRef(function Button(props: IButtonBaseRoot, ref) {
  const {
    children,
    className,
    disabled = false,
    loading = false,
    size = "large",
    startIcon,
    variant = "primary",
    width,
    onClick,
    ...otherProps
  } = props;

  const baseState = {
    disabled,
    loading,
    size,
    variant,
    width,
  };

  const { t } = useTranslation("common");

  const _renderComponentStartIcon = () => {
    if (loading) {
      return <AnimateLoader />;
    }
    return startIcon;
  };

  return (
    <ButtonRoot
      as={MuiButton}
      ref={ref}
      {...baseState}
      disabled={disabled || loading}
      startIcon={_renderComponentStartIcon()}
      onClick={onClick}
      className={className}
      {...otherProps}
    >
      {!loading ? children : t("loading")}
    </ButtonRoot>
  );
});

export default Button;
