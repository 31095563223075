import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

const IconCallCenter = (props: IconProps) => {
  return (
    <SvgIcon {...props} color="inherit" viewBox="0 0 24 24">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8.75C2 3.91751 5.91751 0 10.75 0C15.5825 0 19.5 3.91751 19.5 8.75V9.10352C20.6543 9.42998 21.5 10.4912 21.5 11.75V13.75C21.5 15.1178 20.5015 16.2523 19.1936 16.4644C18.8513 18.6102 16.9922 20.25 14.75 20.25H14.4442C14.2169 21.252 13.3208 22 12.25 22H10.25C9.00736 22 8 20.9926 8 19.75C8 18.5074 9.00736 17.5 10.25 17.5H12.25C13.3208 17.5 14.2169 18.248 14.4442 19.25H14.75C16.4256 19.25 17.8261 18.0726 18.1695 16.5H16.75C16.3358 16.5 16 16.1642 16 15.75V9.75C16 9.33579 16.3358 9 16.75 9H18V8.75C18 4.74594 14.7541 1.5 10.75 1.5C6.74594 1.5 3.5 4.74594 3.5 8.75V9H4.75C5.16421 9 5.5 9.33579 5.5 9.75V15.75C5.5 16.1642 5.16421 16.5 4.75 16.5H2.75C1.23122 16.5 0 15.2688 0 13.75V11.75C0 10.4912 0.845748 9.42998 2 9.10352V8.75ZM2.75 10.5C2.05964 10.5 1.5 11.0596 1.5 11.75V13.75C1.5 14.4404 2.05964 15 2.75 15H4V10.5H2.75ZM20 11.75C20 11.0596 19.4404 10.5 18.75 10.5H17.5V15H18.75C19.4404 15 20 14.4404 20 13.75V11.75ZM9.5 19.75C9.5 19.3358 9.83579 19 10.25 19H12.25C12.6642 19 13 19.3358 13 19.75C13 20.1642 12.6642 20.5 12.25 20.5H10.25C9.83579 20.5 9.5 20.1642 9.5 19.75Z" fill="#2E434D"/>
    </SvgIcon>
  );
};

export default IconCallCenter;
