import { css, SerializedStyles } from "@emotion/react";
import color from "@definitions/mui/color";

export const DialogMain: SerializedStyles = css({
  zIndex: 1301,
});

export const DialogHeader: SerializedStyles = css({
  minHeight: "3.25rem",
  padding: "8px 16px",
  position: "relative",
});

export const DialogBoxTitle: SerializedStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginTop: "20px",
  marginBottom: "20px",
});

export const DialogTitle: SerializedStyles = css({
  textAlign: "center",
  marginTop: "20px",
});

export const DialogBoxPICInfo: SerializedStyles = css({
  display: "flex",
  flexDirection: "column",
  marginTop: "15px",
});

export const DialogBoxIdentityPICInfo: SerializedStyles = css({
  display: "flex",
  flexDirection: "column",
  marginLeft: "26px",
});

export const DialogNumberPICInfo: SerializedStyles = css({
  width: "40px",
  height: "40px",
  background: "#F1F3F7",
  borderRadius: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const DialogCloseButton: SerializedStyles = css({
  position: "absolute",
  right: 12,
  zIndex: 1,
});

export const DialogBody: SerializedStyles = css({
  padding: "0 24px 24px",
});

export const DialogContentSID: SerializedStyles = css({
  display: "flex",
  flexDirection: "row",
  border: "1px solid #DEE3ED",
  borderRadius: 8,
  width: "auto",
  height: 83,
  alignItems: "center",
  padding: 10,
  justifyContent: "space-between",
});

export const DialogContainerSID: SerializedStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: 20,
  gap: 8,
  margin: "4px 0 4px 0",
});

export const DialogSearchBox: SerializedStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

export const CustomDivider: SerializedStyles = css({
  marginTop: "20px",
  marginBottom: "20px",
});

export const DialogNoticeNotes: SerializedStyles = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

// CSS for Mobile View

export const DialogMainMobile: SerializedStyles = css({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  borderTop: `1px solid ${color.background.default}`,
  height: "auto",
  maxHeight: "95%",
  width: "100%",
  zIndex: 99999,
  background: "#FFFFFF",
  boxShadow: "0px -6px 12px rgba(46, 67, 77, 0.1)",
  display: "flex",
  flexDirection: "column",
  padding: 12,
  borderRadius: "20px 20px 0px 0px",
  transform: "translateY(0)",
  transition: "transform 20ms cubic-bezier(0, 0, 0.2, 1) 0ms",
});

export const DialogBodyMobile: SerializedStyles = css({
  padding: "0 13px 13px",
  overflowY: "scroll",
  overflowX: "hidden",
});

export const BoxList: SerializedStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
});

export const DotBullets: SerializedStyles = css({
  width: "5px",
  height: "5px",
  borderRadius: "100%",
  background: "#808C92",
  marginRight: "10px",
});

export const DialogBoxPIC: SerializedStyles = css({
  display: "flex",
  flexDirection: "row",
  marginTop: "15px",
  width: "auto",
  height: "auto",
  borderRadius: 8,
  justifyContent: "space-between",
  alignItems: "center",
});

export const DialogBoxPICMobile: SerializedStyles = css({
  display: "flex",
  flexDirection: "row",
  marginTop: "15px",
  width: "auto",
  height: "auto",
  border: "1px solid #DEE3ED",
  borderRadius: 8,
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px",
});

export const DialogRowGeneral: SerializedStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

export const DialogBoxIdentityPIC: SerializedStyles = css({
  display: "flex",
  flexDirection: "column",
  marginLeft: "20px",
});

export const DialogNumberPIC: SerializedStyles = css({
  width: "40px",
  height: "40px",
  background: "#F1F3F7",
  borderRadius: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
