import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

const IconEmail = (props: IconProps) => {
  return (
    <SvgIcon {...props} color="inherit" fill="none" fillOpacity="0.0" viewBox="0 0 24 24">
      <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" stroke="#2E434D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22 6L12 13L2 6" stroke="#2E434D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};

export default IconEmail;