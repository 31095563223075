import color from "./color";
import customTheme from "./theme";

export const fontFamPrimary: string = `"Open Sans", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;
export const fontFamSecondary: string = `"Nunito", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

const custom: any = {
  button: {
    primary: {
      backgroundColor: color.primary.main,
      borderRadius: "24px",
      boxShadow: `2px 10px 20px 0px #2E434D1A`,
      color: color.white,
      "&:hover": {
        backgroundColor: color.primary.mid,
      }
    },
    secondary: {
      backgroundColor: color.white,
      borderRadius: "24px",
      border: `1px solid ${color.primary.main}`,
      boxShadow: "none",
      color: color.primary.main,
      "&:hover": {
        backgroundColor: color.primary.lighter,
      },
    },
    text: {
      backgroundColor: "transparent",
      color: color.general.main,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: color.grey.mid,
      },
    },
  },
  typography: {
    h1: {
      fontFamily: fontFamPrimary,
      fontSize: 24,
      fontWeight: "600",
      lineHeight: "36px",
    },
    h2: {
      fontFamily: fontFamPrimary,
      fontSize: 20,
      fontWeight: "600",
      lineHeight: "30px",
    },
    h3: {
      fontFamily: fontFamPrimary,
      fontSize: 18,
      fontWeight: "600",
      lineHeight: "27px",
    },
    subtitle1: {
      fontFamily: fontFamPrimary,
      fontSize: 16,
      fontWeight: "600",
      lineHeight: "24px",
    },
    subtitle2: {
      fontFamily: fontFamPrimary,
      fontSize: 14,
      fontWeight: "600",
      lineHeight: "21px",
    },
    body1: {
      fontFamily: fontFamSecondary,
      fontSize: 16,
      fontWeight: "400",
      lineHeight: "24px",
    },
    body2: {
      fontFamily: fontFamSecondary,
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "21px",
    },
    caption1: {
      fontFamily: fontFamSecondary,
      fontSize: 12,
      fontWeight: "400",
      lineHeight: "18px",
    },
    caption2: {
      fontFamily: fontFamSecondary,
      fontSize: 10,
      fontWeight: "400",
      lineHeight: "15px",
    },
    "button-large": {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "30px",
    },
    "button-medium": {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "24px",
    },
    "button-small": {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "18px",
    },
  },
  snackbar: {
    success: {
      backgroundColor: `${color.green.main}`,
    },
  },
};

export default custom;
