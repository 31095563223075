import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

const IconSend = (props: IconProps) => {
  return (
    <SvgIcon {...props} color="inherit" viewBox="0 0 24 24" fill-opacity="0.0" fill="none">
      <path d="M22 2L11 13" stroke="#2E434D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="#2E434D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};

export default IconSend;