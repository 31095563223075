import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

const SvgCloseIcon = (props: IconProps) => {
  return (
    <SvgIcon {...props} color="inherit" viewBox="0 0 22 21">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7819 10.5006L20.7117 2.52809C20.9481 2.28982 21.0808 1.96776 21.0808 1.63209C21.0808 1.29641 20.9481 0.974347 20.7117 0.736086C20.5951 0.618427 20.4563 0.525033 20.3034 0.461297C20.1505 0.397561 19.9865 0.364746 19.8208 0.364746C19.6551 0.364746 19.4911 0.397561 19.3382 0.461297C19.1853 0.525033 19.0465 0.618427 18.9299 0.736086L10.9998 8.70869L3.07007 0.736086C2.95345 0.618427 2.81468 0.525033 2.66178 0.461297C2.50887 0.397561 2.34485 0.364746 2.1792 0.364746C2.01354 0.364746 1.84952 0.397561 1.69661 0.461297C1.54371 0.525033 1.40494 0.618427 1.28833 0.736086C1.05188 0.974347 0.919192 1.29641 0.919192 1.63209C0.919192 1.96776 1.05188 2.28982 1.28833 2.52809L9.21807 10.5007L1.28833 18.4734C1.05188 18.7114 0.919189 19.0333 0.919189 19.3689C0.919189 19.7044 1.05188 20.0263 1.28833 20.2644C1.40498 20.3819 1.54376 20.4753 1.69666 20.539C1.84956 20.6027 2.01356 20.6355 2.1792 20.6355C2.34483 20.6355 2.50883 20.6027 2.66173 20.539C2.81463 20.4753 2.95341 20.3819 3.07007 20.2644L10.9998 12.2916L18.9299 20.2644C19.0466 20.3819 19.1854 20.4753 19.3383 20.539C19.4912 20.6027 19.6552 20.6355 19.8208 20.6355C19.9864 20.6355 20.1504 20.6027 20.3033 20.539C20.4562 20.4753 20.595 20.3819 20.7117 20.2644C20.9481 20.0263 21.0808 19.7044 21.0808 19.3689C21.0808 19.0333 20.9481 18.7114 20.7117 18.4734L12.7819 10.5006Z" fill="currentColor"/>
    </SvgIcon>
  );
};

export default SvgCloseIcon;
