import React from "react";
import clsx from "clsx";
import styled from "@emotion/styled";
import { colorMapper } from "@definitions/mui/color";
import custom from "@definitions/mui/custom";
import { ITypographyProps } from "./interface";

const defaultVariantMapping: any = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "span",
  subtitle2: "span",
  body1: "span",
  body2: "span",
  caption1: "span",
  caption2: "span",
  inherit: "span",
};

const cssBase = styled.span({
  margin: 0
});

export const TypographyRoot = styled("span")(({ baseState }: any) => ({
  margin: 0,
  color: colorMapper(baseState.color ?? "general-main"),
  ...(baseState.clickable && {
    cursor: "pointer"
  }),
  ...(baseState.disabled && {
    color: colorMapper("general-light")
  }),
  ...(baseState.variant && custom.typography[baseState.variant]),
  ...(baseState.align !== 'inherit' && {
    textAlign: baseState.align,
  }),
  ...(baseState.width && {
    width: baseState.width,
  }),
  ...(baseState.noWrap && {
    display: "block",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  ...(baseState.paragraph && {
    marginBottom: 16,
  }),
  ...(baseState.weight && {
    fontWeight: baseState.weight
  }),
  ...(baseState.sx && {
    ...baseState.sx
  })
}));

const Text = React.forwardRef(function Text(props: ITypographyProps, ref) {
  const {
    align = "inherit",
    component,
    children,
    className,
    color,
    clickable,
    disabled,
    noWrap = false,
    paragraph = false,
    variant = "body1",
    variantMapping = defaultVariantMapping,
    weight = "600",
    width,
    sx,
    onClick,
    ...otherProps
  } = props;

  const baseState = {
    ...props,
    align,
    className,
    clickable,
    color,
    disabled,
    noWrap,
    variant,
    variantMapping,
    weight,
    width,
    sx
  };

  const Component =
    component || (paragraph ? "p" : variantMapping[variant] || defaultVariantMapping[variant] || "span");

  return (
    <TypographyRoot
      as={Component}
      ref={ref}
      baseState={baseState}
      className={clsx(cssBase, className)}
      onClick={onClick}
      title={noWrap ? children : undefined}
      {...otherProps}
    >
      {children}
    </TypographyRoot>
  );
});

export default Text;
